import React from "react";

const Contact = () => {
  return (
    <>
      {/* <!-- Contatc Area */}
      {/* ============================================ --> */}
      <div className="container pb-90 pt-0">
        <div className="d-flex justify-content-center align-items-center">
          <h1
            className="text-dark fs-sm"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            DRIVE MORE &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;{" "}
          </h1>
          <h1
            className="text-red fs-sm"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            EARN MORE
          </h1>
        </div>
        <div className="w-50 mx-auto mt-5 w-sm-100">
          <p className="text-center" style={{ fontSize: "1.5em" }}>
            Brother Union delivers success! Our fleet partners earn top industry
            pay! Earn more money and unbeatable experience with unparalleled
            success and benefits. We've got more freight than ever and pay our
            elite drivers handsomely for on-time delivery. Become a Brother
            Union partner today!
          </p>
          <p className="text-center" style={{ fontSize: "1.5em" }}>
            If truck ownership is your goal, learn the business side of the
            trucking industry with no investment. Drive for a Fleet Owner with
            newer equipment – no hassles of truck ownership. Drive for Fleet
            Owners with extensive industry knowledge.
          </p>
        </div>
      </div>
      <section
        className="hearo-area  d-flex justify-content-end align-items-center h-100vh"
        style={{
          backgroundImage: "url('/assest/images/5.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="w-50 mx-auto">
          <div className="bg-red d-flex justify-content-center align-items-center p-3 ">
                <h2 className="text-white font">call 386-517-3629</h2>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
