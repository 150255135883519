import React from "react";

const About = () => {
  return (
    <>
      {/* <!-- Feature Area
============================================ --> */}
      <div id="feature-area" className="feature-area bg-gray pt-10 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-slide-content">
                <h1 className="text-left text-red">
                  Let's deliver
                  <br /> excellence.
                  <br /> Together
                </h1>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ marginTop: "75px", fontSize: "1.5em" }}>
                <p className="text-dark ">
                  Discover how Brother Union's culture revolves around our contractors
                  and how we are committed to ensuring that your business will
                  prosper.
                </p>
                <p className="text-dark">
                  Experience the numerous programs designed to keep you moving
                  and profitable. Learn what our current contractors think about
                  Brother Union and why they are considered the best in the business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        className="hearo-area  slideshow-bg overlay overlay-black overlay-60 fix pb-90"
        style={{
          backgroundImage: "url('/assest/images/3.jpg')",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="d-flex justify-content-end align-items-end hero-slide-content flex-column pr-5 pr-sm-0 mr-5 mr-sm-0">
            <h1 className="text-left">
              WHY
              <br /> WAIT?
            </h1>
            <h4 className="font text-white text-start">
              Start earning more today.
            </h4>
            <div className="bg-red d-flex justify-content-center align-items-center p-3">
              <h2 className="text-white font">call 386-517-3629</h2>
            </div>
          </div>
        </div>
      </section>
      <div id="feature-area" className="feature-area bg-dark-gray pt-10 pb-70">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4">
              <div className="font m-5">
                <h1 className="text-left text-red fs-sm">MEET THESE REQUIREMENTS:</h1>
                <ul
                  className="text-white m-3 fs-1 w-sm-100"
                >
                  <li className="mb-2">•At least 21 years of age</li>
                  <li className="mb-2">
                  •No DUI or drug convictions for 5 years
                  </li>
                  <li className="mb-2">•No felonies in the last 10 years</li>
                  <li className="mb-2"> •CDL-A + 6 months minimum experience</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="font m-5">
                <h1 className="text-left text-red fs-sm">GET UNMATCHED BENEFITS:</h1>
                <ul
                  className="text-white m-3 fs-1 w-sm-100"
                >
                  <li className="mb-2">•Multiple Lease Purchase Options Available</li>
                  <li className="mb-2">
                  •Outstanding Driver Rewards program
                  </li>
                  <li className="mb-2">•Paid Fuel Surcharge with access to Fuel Discounts</li>
                  <li className="mb-2">•Opportunities to drive for Brother Union Fleet Owners</li>
                  <li className="mb-2">•Weekly Settlements</li>
                  <li className="mb-2">•99% No Touch Freight</li>
                  <li className="mb-2">•Competitive Sign-On BonusesFreight</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
