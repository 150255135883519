import React, {useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

const Banner = () => {

// useEffect(()=>{
// navigate("/402442");
// },[])



  const { query } = useParams();
  console.log(query, "param1Value");



  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [Leased, setLeased] = useState("");
  const [own, setOwn] = useState("");
  const [loader, setLoader] = useState(false);

  // const navigate = useNavigate();

  const addapi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("name", firstname);
    data.append("lname", lastname);
    data.append("email", email);
    data.append("password", password);
    data.append("phone", number);
    data.append("ref_code", query ? query : code);
    data.append("type", type);
    data.append("leased", Leased);
    data.append("own", own);
    data.append("role", "user");

    console.log(Leased, own, "data");
    var config = {
      method: "POST",
      url: `https://thedriveapi.dev-sh.xyz/api/user/signup`,
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "api");
        if (response.data.status === true) {
          setLoader(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          window.location.href = "https://thedrive.dev-bt.xyz/";
        } else {
          setLoader(false);
        }
      })
      .catch(function (errors) {
        console.log(errors?.response?.data?.errors)
        setLoader(false);
      })
  };
  return (
    <>
      {/* <!-- Hero Area
============================================ --> */}
      <section
        className="hearo-area hero-static slideshow-bg overlay overlay-black overlay-60 fix"
        style={{
          backgroundImage: "url('/assest/images/Asset-Image-5.jpg')",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div className="container-fluid">
          <div className=" d-flex justify-content-end w-100 mt-3">
            <Link to="https://thedrive.dev-bt.xyz" className="login">
              {/* <button className="bg-red p-3 w-15 border-0 text-white"> */}
              Login
              {/* </button> */}
            </Link>
          </div>
          <div className="row p-5 mx-auto w-100">
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
              <img src="/logo.png" alt="not found" className="logo" />
              <div className="number d-flex justify-content-center align-items-center">
                <h2 className="text-white font">call 386-517-3629</h2>
              </div>
              <div className="hero-slide-content ">
                <h1 className="">
                  PREMIUM
                  <br />
                  TRANSPORTATION.
                </h1>
                <h1 className="mt-5">
                  PREMIUM
                  <br />
                  PAY.
                </h1>
              </div>
            </div>
            <div className="col-md-6 repsonsive">
              <h2 className="text-white font">
                Learn more about our <br /> competitive offers
              </h2>
              <hr className="hr" />
              <br />
              <div className="form">
                <form className="w-60 w-sm-100">
                  <input
                    className=" w-100 p-1 mb-3 "
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    className=" w-100 p-1 mb-3"
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <input
                    className=" w-100 p-1 mb-3"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    className=" w-100 p-1 mb-3"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    className=" w-100 p-1 mb-3"
                    type="text"
                    placeholder="Phone Numer"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <input
                    className=" w-100 p-1 mb-3"
                    type="text"
                    placeholder="Refral Code (Optional)"
                    value={query}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <select
                    className="w-100 p-2 mb-3"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option selected>CDL Type</option>
                    <option value="class A">Class A</option>
                    <option value="class B">Class B</option>
                    <option value="class C">Class C</option>
                    <option value="None ">None</option>
                  </select>
                  <h5 className="font text-white">I am interested in:</h5>
                  <input
                    type="checkbox"
                    value="Leased Truck"
                    onChange={(e) => setLeased(e.target.value)}
                  />
                  <span className="text-white font ml-3">Leased Truck</span>
                  <br />
                  <input
                    type="checkbox"
                    value="Owner/Operator"
                    onChange={(e) => setOwn(e.target.value)}
                  />
                  <span className="text-white font ml-3">Owner/Operator</span>
                  <button type="button" className="btn-theme d-flex justify-content-center" onClick={addapi}>
                    {loader ? (
                      <Oval
                        height={30}
                        width={30}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
