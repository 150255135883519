import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./component/About/About";
import Contact from "./component/Contact/Contact";
import Gallery from "./component/gallery/Gallery";
import Home from "./component/Home/Home";


function App() {
  return (
    <>
      <BrowserRouter>
      <Routes >
      <Route path="/" element={<Home />}></Route>
      <Route path="/:query" element={<Home />}></Route>
      <Route path="/gallery" element={<Gallery />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/about" element={<About />}></Route>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
