import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- Footer Area */}
      {/* ============================================ --> */}
      <div className="footer-area overlay overlay-black overlay-70 pt-0">
        <div className="container">
          <div className="footer-bottom text-center col-12">
            <p className="copyright">
              Copyright &copy; <Link to="#">The Drive</Link> ALL Right Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
