import React from 'react'
import About from '../About/About'
import Banner from '../Banner/Banner'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import "../Header/style.css";

// import Header from '../Header/Header'

const Home = () => {
  return (
    <>
        {/* <Header /> */}
        <Banner/>
        <About/>
        <Contact/>
        <Footer/>
            </>
  )
}

export default Home