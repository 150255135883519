import React from "react";

const Gallery = () => {
  return (
    <>
      {/* <!-- Gallery Area */}
      {/* ============================================ --> */}
    
      <div id="gallery-area" className="gallery-area bg-gray pt-90 pb-90">
        <div className="container">
          {/* <!-- Gallery Grid --> */}
          <div className="row">
            <div className="section-title text-center col-12 mb-45">
              <h3 className="heading">The Drive Gallery</h3>
              <div className="excerpt">
                <p>
                  Lorem ipsum dolor sit amet, consectetur maksu rez do eiusmod
                  tempor magna aliqua
                </p>
              </div>
              <i className="icofont icofont-traffic-light"></i>
            </div>
          </div>
          <div className="gallery-grid row mb-20" id="gallery">
            <div className="gallery-item cars col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/1.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/1.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            {/* <div className="gallery-item cars  col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/2.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/2.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/3.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/3.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div> */}
            <div className="gallery-item cars students exam col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/4.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/4.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            {/* <div className="gallery-item cars students col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/5.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/5.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item students classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/6.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/6.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div> */}
            {/* <div className="gallery-item cars col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/7.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/7.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div> */}
            <div className="gallery-item cars exam col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/8.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/8.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/9.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/9.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            {/* <div className="gallery-item cars students exam col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/10.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/10.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item cars students col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/11.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/11.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item students classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/12.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/12.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div> */}
            <div className="gallery-item cars col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/13.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/13.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            {/* <div className="gallery-item cars exam col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/14.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/14.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/15.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/15.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div> */}
            <div className="gallery-item cars students exam col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/16.jpg" className="gallery-image image-popup h-50">
                <img src="/assest/img/gallery/16.jpg" alt="" />
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item cars students col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/17.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/17.jpg" alt="" style={{height: "60vh"}}/>
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
            <div className="gallery-item students classNameroom col-lg-3 col-md-4 col-12">
              <a href="/assest/img/gallery/18.jpg" className="gallery-image image-popup">
                <img src="/assest/img/gallery/18.jpg" alt="" style={{height: "60vh"}}/>
                <div className="content">
                  <i className="icofont icofont-search"></i>
                  <h4>className Test</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
